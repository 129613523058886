<template>
  <div class="transaction-benefits color-transaction">
    <b-btn
      block
      href="#"
      v-b-toggle.accordion1
      variant="secondary"
      class="d-flex justify-content-between mt-4 benefitsbtn"
    >
      <div class="transaction-benefits-heading">
        <b-img src="img/benefits-icon.png" class="invert-color"></b-img>
        Transaction Benefits
      </div>

      <span class="when-opened">
        <b-icon icon="chevron-down"></b-icon>
      </span>
      <span class="when-closed">
        <b-icon icon="chevron-right"></b-icon>
      </span>
    </b-btn>
    <b-collapse id="accordion1" role="tabpanel">
      <div class="showbenefits-div">
        <b-card
          title="Your Cash Back"
          class="mb-4 txn-benefits-cards your-cashback"
        >
          <b-card-text class="mb-0 d-flex justify-content-between">
            Dhamaka Cashback:
            <p>
              &#x20B9;
              {{
                transactionBenefits &&
                transactionBenefits[0] &&
                transactionBenefits[0].value > transactionBenefits &&
                transactionBenefits[1] &&
                transactionBenefits[1].value
                  ? (transactionBenefits &&
                      transactionBenefits[0] &&
                      transactionBenefits[0].value) ||
                    0
                  : (transactionBenefits &&
                      transactionBenefits[1] &&
                      transactionBenefits[1].value) ||
                    0
              }}
            </p>
          </b-card-text>
          <b-card-text class="d-flex justify-content-between"
            >Promotional Cashback:
            <p>
              &#x20B9;
              {{
                transactionBenefits && transactionBenefits[2]
                  ? transactionBenefits[2].value
                  : 0
              }}
            </p>
          </b-card-text>
        </b-card>
        <b-card
          title="Your Referrer Cash Back"
          class="mb-4 txn-benefits-cards referrer-cashback"
        >
          <b-card-text class="mb-0 d-flex justify-content-between">
            Double Dhamaka Cashback:
            <p>
              &#x20B9;
              {{
                transactionBenefits &&
                transactionBenefits[0] &&
                transactionBenefits[0].value > transactionBenefits &&
                transactionBenefits[1] &&
                transactionBenefits[1].value
                  ? (transactionBenefits &&
                      transactionBenefits[0] &&
                      transactionBenefits[0].value) ||
                    0
                  : (transactionBenefits &&
                      transactionBenefits[1] &&
                      transactionBenefits[1].value) ||
                    0
              }}
            </p>
          </b-card-text>
          <b-card-text class="d-flex justify-content-between"
            >Referrer Cashback:
            <p>
              &#x20B9;
              {{
                transactionBenefits && transactionBenefits[3]
                  ? transactionBenefits[3].value
                  : 0
              }}
            </p>
          </b-card-text>
        </b-card>
        <b-card class="txn-benefits-cards your-own-akshaya-patra">
          <b-card-text class="d-flex akshaya-patra">
            Your Own Akshaya Patra:
          </b-card-text>
          <p class="akshaya-patra-points">
            {{
              transactionBenefits && transactionBenefits[0]
                ? transactionBenefits[0].value
                : 0
            }}
          </p>
        </b-card>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "transaction-benefits",

  computed: {
    ...mapState({
      transactionBenefits: (state) => state.checkout.transactionBenefits,
      checkoutData: (state) => state.checkout.checkoutData,
    }),
  },
  watch: {
    async checkoutData(newVal) {
      try {
        if (newVal.listPrice) {
          {
            await this.$store.dispatch("checkout/transactionBenefitsFinal");
          }
        }
      } catch {
        console.log("");
      }
    },
  },
};
</script>
<style scoped>
.transaction-benefits .benefitsbtn {
  background-color: rgba(0, 0, 0, 0.03) !important;
  padding: 12px 10px;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;

  color: #272727 !important;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.showbenefits-div {
  padding: 12px 12px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 10px 10px;
}

.showbenefits-div ul {
  border-bottom: 1px solid #ddd;
}

.invert-color {
  filter: invert(1);
}

.transaction-benefits-heading {
  font-size: 18px;
  font-weight: 500;
}

.address-details {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  text-transform: capitalize;
  color: #9e9e9e;
}

.text-danger {
  color: #fe7b08 !important;
}

.price-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}

.price-details {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.price-total-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  color: #14982b;
}

.btn-outline-primary {
  width: 180px;
  height: 45px;
}

.btn-primary {
  width: 180px;
  height: 45px;
  border-radius: 3px;
}

.btn-outline-primary:hover {
  width: 180px;
  height: 45px;
}

.txn-benefits-cards {
  border-radius: 8px;
}

.your-cashback {
  background-image: linear-gradient(to right, #fc4f31, #ff7632);
  color: #ffff;
}

.referrer-cashback {
  background-image: linear-gradient(to right, #f5669c, #e44594);
  color: #ffff;
}

.your-own-akshaya-patra {
  background-image: linear-gradient(to right, #2a4ea7, #147f4b);
  color: #ffff;
}

.akshaya-patra-heading {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0;
  width: 70%;
  margin-right: 2%;
  float: left;
}

.akshaya-patra {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0;
  width: 100%;
  float: left;
}
.akshaya-patra-points {
  font-size: 1.6rem;
  margin-left: 20px;
  float: right;
}
</style>
