var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transaction-benefits color-transaction"
  }, [_c('b-btn', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion1",
      modifiers: {
        "accordion1": true
      }
    }],
    staticClass: "d-flex justify-content-between mt-4 benefitsbtn",
    attrs: {
      "block": "",
      "href": "#",
      "variant": "secondary"
    }
  }, [_c('div', {
    staticClass: "transaction-benefits-heading"
  }, [_c('b-img', {
    staticClass: "invert-color",
    attrs: {
      "src": "img/benefits-icon.png"
    }
  }), _vm._v(" Transaction Benefits ")], 1), _c('span', {
    staticClass: "when-opened"
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-down"
    }
  })], 1), _c('span', {
    staticClass: "when-closed"
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)]), _c('b-collapse', {
    attrs: {
      "id": "accordion1",
      "role": "tabpanel"
    }
  }, [_c('div', {
    staticClass: "showbenefits-div"
  }, [_c('b-card', {
    staticClass: "mb-4 txn-benefits-cards your-cashback",
    attrs: {
      "title": "Your Cash Back"
    }
  }, [_c('b-card-text', {
    staticClass: "mb-0 d-flex justify-content-between"
  }, [_vm._v(" Dhamaka Cashback: "), _c('p', [_vm._v(" ₹ " + _vm._s(_vm.transactionBenefits && _vm.transactionBenefits[0] && _vm.transactionBenefits[0].value > _vm.transactionBenefits && _vm.transactionBenefits[1] && _vm.transactionBenefits[1].value ? _vm.transactionBenefits && _vm.transactionBenefits[0] && _vm.transactionBenefits[0].value || 0 : _vm.transactionBenefits && _vm.transactionBenefits[1] && _vm.transactionBenefits[1].value || 0) + " ")])]), _c('b-card-text', {
    staticClass: "d-flex justify-content-between"
  }, [_vm._v("Promotional Cashback: "), _c('p', [_vm._v(" ₹ " + _vm._s(_vm.transactionBenefits && _vm.transactionBenefits[2] ? _vm.transactionBenefits[2].value : 0) + " ")])])], 1), _c('b-card', {
    staticClass: "mb-4 txn-benefits-cards referrer-cashback",
    attrs: {
      "title": "Your Referrer Cash Back"
    }
  }, [_c('b-card-text', {
    staticClass: "mb-0 d-flex justify-content-between"
  }, [_vm._v(" Double Dhamaka Cashback: "), _c('p', [_vm._v(" ₹ " + _vm._s(_vm.transactionBenefits && _vm.transactionBenefits[0] && _vm.transactionBenefits[0].value > _vm.transactionBenefits && _vm.transactionBenefits[1] && _vm.transactionBenefits[1].value ? _vm.transactionBenefits && _vm.transactionBenefits[0] && _vm.transactionBenefits[0].value || 0 : _vm.transactionBenefits && _vm.transactionBenefits[1] && _vm.transactionBenefits[1].value || 0) + " ")])]), _c('b-card-text', {
    staticClass: "d-flex justify-content-between"
  }, [_vm._v("Referrer Cashback: "), _c('p', [_vm._v(" ₹ " + _vm._s(_vm.transactionBenefits && _vm.transactionBenefits[3] ? _vm.transactionBenefits[3].value : 0) + " ")])])], 1), _c('b-card', {
    staticClass: "txn-benefits-cards your-own-akshaya-patra"
  }, [_c('b-card-text', {
    staticClass: "d-flex akshaya-patra"
  }, [_vm._v(" Your Own Akshaya Patra: ")]), _c('p', {
    staticClass: "akshaya-patra-points"
  }, [_vm._v(" " + _vm._s(_vm.transactionBenefits && _vm.transactionBenefits[0] ? _vm.transactionBenefits[0].value : 0) + " ")])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }